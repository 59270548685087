/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

/**
 * Get token from meta tag in header and add CSRF token to the ajax request
 * 
 * @author Rick van der Burg <rick@click.nl>
 */
function prepareForAjax() {
    $.ajaxSetup(
        {
            headers:
            {
                'X-CSRF-Token': $('meta[name="_token"]').prop('content')
            }
        });
}

prepareForAjax();


/**
 * Random generate parallax scrolling effect
 *
 * @author Mannus van der Meer <mannus@click.nl>
 */
 var network = function(){
    var self = this;
    var key             = 0;
    var array_WH        = ['75', '100', '125', '150'];
    var array_shadow    = ['4px', '6px', '8px', '10px'];
    var array_depth     = ['0.1', '0.2', '0.3', '0.4'];
    var array_color     = ['#5DB5A1', '#52B3CD', '#B387BC', '#F39B96'];

    self.init = function(){
        $('.js-network').find('.js-network-item-wrapper').each( function(){
            // Generate random array value
            var random_array    = Math.floor(Math.random() * 4) + 0;

            // Get item width
            var width_item      = $(this).innerWidth();
            // Depth ratio
            var WH_ratio     = 0;
            // Set WH_ratio for array_depth in relation to the width
            switch ( width_item ){ 
                case 75: WH_ratio = 0; break;
                case 100: WH_ratio = 1; break;
                case 125: WH_ratio = 2; break;
                case 150: WH_ratio = 3; break;
            }


            // Give the item depth in relation to the width
            $( this ).attr( 'data-depth', array_depth[ WH_ratio ] );
            // Add random background color
            $( this ).find('.js-network-item').css({ 'background': array_color[ random_array ], 'box-shadow': '0px 0px 0px '+ array_shadow[ WH_ratio ]+' rgba(0, 0, 0, 0.1)'});

            // Display the network items
            $( this ).addClass('big');
        });
    }

    self.fullRandom = function(){


        // For each item within the parrent parameter
        $('.js-network').find('.js-network-item-wrapper').each( function(){
            key++;

            // Get some standard variables.
            var height_div  = $('.js-network').innerHeight();
            var width_div   = $('.js-network').innerWidth();


            // Generate a top, left value and a array value
            var random_top      = Math.floor(Math.random() * height_div) + 0;
            var random_left     = Math.floor(Math.random() * width_div) + 0;
            var random_array    = Math.floor(Math.random() * 4) + 0;

            // Set all the style they need
            $( this ).css({ 'margin-top': ( random_top ), 'margin-left': random_left, 'width': array_WH[ random_array ], 'height': array_WH[ random_array ] });
            // Give the item depth in relation to the width
            $( this ).attr( 'data-depth', array_depth[ random_array ] );
            // Add random background color
            $( this ).find('.js-network-item').css({ 'background': array_color[ random_array ] });

            // Display the network items
            $( this ).addClass('big');

        });
    }
}


/**
 * Menu toggle
 *
 * @author Mannus van der Meer <mannus@click.nl>
 */
var menu = function(){
    var self = this;

    self.init = function(){
        self.scrollSpy();
        self.menuToggle();
        self.menuAnchorToggle();
    }

    self.scrollSpy = function(){
        // When the user scrolls
        $(window).scroll(function() {

            // If the user scrolls past 200 pixels
            if( $(this).scrollTop() > 200 ) {
                // Set the topbar on position fixed
                $('.js-top-bar').addClass('is-fixed');
            }else{
                // Remove position fixed
                $('.js-top-bar').removeClass('is-fixed');
            }
            
            // If the user scrolls past the trigger
            if( $(this).scrollTop() > $('.js-trigger-top-bar').offset().top ) {
                // Show top bar incl menu
                $('.js-top-bar').addClass('is-at-top');

            // If the user scrolls back the trigger
            }else{
                // Hide the top var incl menu
                $('.js-top-bar').removeClass('is-at-top');
                // If the menu was open
                if( $('.js-menu-toggle').hasClass('is-open') ){
                    // Remove all classes tho hide open menu
                    $('.js-menu-toggle').removeClass('is-open');
                    $('.js-menu').removeClass('is-open');
                    $('.js-top-bar').removeClass('is-open');
                    $('.js-overlay').removeClass('is-open');
                    $('.js-main').removeClass('is-blured');
                }
            }
        });
    }

    self.menuToggle = function(){
        // When the user clicks the menu toggle
        $('.js-menu-toggle').click(function(){
            // toggle all menu open classes
            $(this).toggleClass('is-open');
            $('.js-menu').toggleClass('is-open');
            $('.js-top-bar').toggleClass('is-open');
            $('.js-overlay').toggleClass('is-open');
            $('.js-main').toggleClass('is-blured');
        })
    }

    self.menuAnchorToggle = function(){
        // When the user clicks on an anchor
        $('.js-menu-anchor').click(function(){
            // Remove all classes tho hide open menu
            $('.js-menu-toggle').removeClass('is-open');
            $('.js-menu').removeClass('is-open');
            $('.js-top-bar').removeClass('is-open');
            $('.js-overlay').removeClass('is-open');
            $('.js-main').removeClass('is-blured');
        })
    }
}


/**
 * Slope responsive effect
 *
 * @author Mannus van der Meer <mannus@click.nl>
 */
var slope = function(){
    var self = this;

    self.init = function(){
        $(window).on('load resize', function () {
            // The width of the main content
            var width_border    = $('.js-main').innerWidth();
            // Set width border and disable tmep height
            $('.js-slope').css({ 'border-left': width_border +'px solid transparent', 'height': 0 });

        });
    }
}


/**
 * Animated Titles
 * 
 * Author: Mannus van der Meer <mannus@click.nl>
 */
 var animated_text = function(){
    var self = this;

    self.init = function(){
        self.fadeInBlock();
        $(window).scroll(function(){
            self.fadeInBlock();
        });
    }
    self.fadeInBlock = function(){
        // For each fader offset
        $('.js-fader-offset').each(function(){
            // For each fader
            $(this).find('.js-fader').each(function(){


                if( !$(this).hasClass('fadeInUp')){

                    // Variables
                    var bottom_page = $(window).scrollTop() + $(window).height();
                    var top_item    = $(this).closest('.js-fader-offset').offset().top;
                    var half_item   = $(this).closest('.js-fader-offset').outerHeight() / 2;

                    // When viewpoint passes text
                    if ( bottom_page > ( top_item + half_item ) ){


                        // Add class fadeInUp
                        $(this).addClass('fadeInUp');
                        // When the animation end
                        $(this).one('webkitAnimationEnd oAnimationEnd msAnimationEnd animationend', function(event) {
                            // Add the grad to the titles that need it
                            $(this).find('.js-has-grad').addClass('grad');
                        });
                        
                        // Remove class invisible
                        $(this).find('.js-fader').removeClass('invisible');
                    }
                }
            });
        });
    }
}


/**
 * Massive Mobile Madness
 * 
 * Author: Mannus van der Meer <mannus@click.nl>
 */
 var massive_mobile = function(){
    var self = this;

    self.init = function(){
        // Init controller
        var controller = new ScrollMagic.Controller();


        // Build scene for text 1
        var scene = new ScrollMagic.Scene({triggerElement: '#trigger-text-1', duration: 600})
                        .triggerHook(0.3)                                            // TriggerHook distance
                        .setPin('#text1', {spacerClass: 'scrollmagic-pin-text'})     // When to pin on the screen
                        .addTo(controller);                                          // Add to the controller

        // Build scene for text 2
        var scene = new ScrollMagic.Scene({triggerElement: '#text2', duration: 400})
                        .triggerHook(0.38)
                        .setPin('#text2', {spacerClass: 'scrollmagic-pin-text'})
                        .addTo(controller);

        // Build scene for text 3
        var scene = new ScrollMagic.Scene({triggerElement: '#text3', duration: 400})
                        .triggerHook(0.38)
                        .setPin('#text3', {spacerClass: 'scrollmagic-pin-text'})
                        .addTo(controller);

        // Wrapper showoff variables
        var wrapper = $('.js-app-showoff');
        var wrapper_height = Math.round( wrapper.innerHeight() );

        // Masive mobile variables
        var mobile = $('.js-massive-mobile');
        var mobile_height = Math.round( mobile.innerHeight() );

        // Calculate the duration of the whole wrapper minus the massive mobile
        var duration = wrapper_height - mobile_height;

        // Build scene for massive mobile
        var scene = new ScrollMagic.Scene({triggerElement: '#trigger-mobile', duration: duration})
                        .triggerHook(0.35)
                        .setPin('#massive-mobile', {pushFollowers: false, spacerClass: 'scrollmagic-pin-mobile'})
                        .addTo(controller);



        // define images
        var images = [];
        for( var i = 0; i < 150; i++){
            if( i < 10 ){
                var no = '000' + i;
            }else if( i < 100 ){
                var no = '00' + i;
            }else{
                var no = '0' + i;
            }
            images.push('/assets/images/frontend/animation/Scherm_HeyU_test'+no+'.jpg');
        }

        // TweenMax can tween any property of any object. We use this object to cycle through the array
        var obj = {curImg: 0};

        // create tween
        var tween = TweenMax.to(obj, 0.5,
            {
                curImg: images.length - 1,          // animate propery curImg to number of images
                roundProps: 'curImg',               // only integers so it can be used as an array index
                repeat: 0,                          // repeat 3 times
                immediateRender: true,              // load first image automatically
                ease: Linear.easeNone,              // show every image the same ammount of time
                onUpdate: function () {
                  $('#massive-mobile-screen').attr('src', images[obj.curImg]); // set the image source
                }
            }
        );








        // Build scene for massive mobile inner screen
        var scene = new ScrollMagic.Scene({triggerElement: '#trigger-mobile', duration: duration})
                        .triggerHook(0.35)
                        .setPin('#massive-mobile-screen', {pushFollowers: false, spacerClass: 'scrollmagic-pin-mobile-screen'})
                        .setTween(tween)
                        .addTo(controller);

        // Build scene for scroll indicator
        var scene = new ScrollMagic.Scene({triggerElement: '#trigger-scroll-indicator', duration: duration})
                        .triggerHook(0.35)
                        .setPin('#scroll-indicator-wrapper', {pushFollowers: false, spacerClass: 'scrollmagic-pin-mobile'})
                        .setTween(tween)
                        .addTo(controller);
    }
 }